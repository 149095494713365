import React, { useState } from "react";

import login_vector from "../../../assets/images/login_vector.jpg";

import "./Login.scss";
import {
  LoaderButton,
  SecondaryButton,
} from "../../../components/buttons/Button";

import { Link } from "react-router-dom";
// import axios from "axios";
// import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
// import { URL } from "../../../redux/api/Api";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import OtpInput from "react-otp-input";
import * as Validator from "validatorjs";
import { POST } from "../../../constant/RequestAuthService";
import StoredVariables, {
  GetLocalSession,
  GetSession,
  RemoveLocalSession,
  SetLocalSession,
  SetSession,
} from "../../../constant/Session";
import { Stringify, ENCODE } from "../../../constant/Util";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Checkbox, IconButton } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Login = () => {
  const [Login, setLogin] = useState({
    email: "",
    password: "",
  });
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showPassword1, setShowPassword1] = useState(true);
  const [remember, setIsRemember] = useState(false);

  const navigate = useNavigate();
  const isLogin = GetSession(StoredVariables.username);

  useEffect(() => {
    if (isLogin) {
      navigate("/");
    } else {
      SetLocalSession(StoredVariables.RememberMe, ENCODE(Stringify("true")));
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChange = (e) => {
    e.preventDefault();
    setLogin({ ...Login, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const checkdata = {
      email: "required|email",
      password: "required",
    };
    const validation = new Validator(Login, checkdata);

    if (validation.fails()) {
      setLoading(true);
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading(false);

      // toast.error("fail");
      // await Validation(validation.errors.errors);
    } else {
      const response = await POST("login", Login);
      // console.log(response.status);
      if (response.status === 200) {
        // console.log(response.data);
        setLoading(true);
        toast.success(response.message);
        SetSession(StoredVariables.logindata, ENCODE(Stringify(response.data)));
        SetSession(StoredVariables.email, Login.email);
        SetSession(StoredVariables.username, response.data.name);
        SetSession(StoredVariables.role, response.data.Role.role);
        if (remember === true) {
          SetLocalSession(
            StoredVariables.email,
            ENCODE(Stringify(Login.email))
          );
          SetLocalSession(
            StoredVariables.password,
            ENCODE(Stringify(Login.password))
          );
        }
        SetSession(StoredVariables.reload, true);

        setTimeout(() => {
          if (GetSession(StoredVariables.checkout)) {
            navigate("/orderStepper");
          } else {
            navigate("/");
          }
        }, 300);
        setLoading(false);
      } else {
        toast.error(response.message);
        setLoading(true);
        setLoading(false);
      }
    }
  };

  const handleOTPSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const bodyData = {
      phone: number,
    };
    const checkdata = {
      phone: "required|numeric",
    };
    const validation = new Validator(bodyData, checkdata);

    if (validation.fails()) {
      setLoading(true);
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading(false);
    } else {
      const response = await POST("login/mobile", bodyData);
      if (response.status === 200) {
        toast.success(response.message);
        setShow(true);
      } else {
        toast.error(response.message);
      }
      setLoading(false);
    }
  };

  const handleOTPVerify = async (e) => {
    e.preventDefault();
    setLoading(true);
    const bodyData = {
      phone: number,
      otp: parseInt(otp),
    };
    const checkdata = {
      otp: "required",
    };
    const validation = new Validator(bodyData, checkdata);

    if (validation.fails()) {
      setLoading(true);
      const errorData = Object.values(validation.errors.errors);
      errorData.map((x) => toast.error(`${x}`));
      setLoading(false);
    } else {
      const response = await POST("login/mobile/otp", bodyData);
      // console.log(response.status);
      if (response.status === 200) {
        setLoading(true);
        toast.success(response.message);
        SetSession(StoredVariables.logindata, ENCODE(Stringify(response.data)));
        // SetSession(StoredVariables.email, Login.email);
        SetSession(StoredVariables.username, response.data.name);
        SetSession(StoredVariables.role, response.data.Role.role);

        setTimeout(() => {
          if (GetSession(StoredVariables.checkout)) {
            navigate("/orderStepper");
          } else {
            navigate("/");
          }
        }, 300);
        setLoading(false);
      } else {
        toast.error(response.message);
        // setLoading(true);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const isRememberme = GetLocalSession(StoredVariables.RememberMe);
    if (typeof isRememberme !== "object") {
      setIsRemember(true);
    }
  }, []);

  const RememberMe = () => {
    const isRememberme = GetLocalSession(StoredVariables.RememberMe);
    // console.log(isRememberme);
    if (isRememberme) {
      RemoveLocalSession(StoredVariables.RememberMe);
      setIsRemember(false);
    } else {
      SetLocalSession(StoredVariables.RememberMe, ENCODE(Stringify("true")));
      setIsRemember(true);
    }
  };

  return (
    <>
      <div className="container p-4 mt-5">
        <div className="row">
          <div className="col-sm-6">
            <img src={login_vector} alt="log" className="img-fluid" />
          </div>
          <div className="col-sm-6  d-flex align-items-center justify-content-center flex-column">
            <Tabs>
              <TabList>
                <Tab>Login With Password</Tab>
                <Tab>Login With OTP</Tab>
              </TabList>

              <TabPanel>
                <form style={{ width: "100%" }} onSubmit={handleSubmit}>
                  {/* <div className="relative">
                  <div className="required">*</div> */}

                  <input
                    name="email"
                    value={Login.email}
                    onChange={handleChange}
                    type="email"
                    placeholder="Enter Email*"
                    className="login_field "
                  />
                  {/* </div> */}
                  {/* <div className="relative mt-4 p-0">
                  <div className="required">*</div> */}
                  <div className="login_field relative p-0 mt-4 ">
                    <input
                      name="password"
                      value={Login.password}
                      onChange={handleChange}
                      type={!showPassword1 ? "text" : "password"}
                      placeholder={`Password *`}
                      className="login_field "
                    />

                    <IconButton
                      className="paswword_icon pointer"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 ? (
                        <VisibilityIcon />
                      ) : (
                        <VisibilityOffIcon />
                      )}
                    </IconButton>
                    {/* </div> */}
                  </div>
                  <div className="d-flex flex-wrap justify-content-between align-items-center login_btns ">
                    <div>
                      <p className="fs_14 mt-3 ms-2">
                        Don't have an account?
                        <Link
                          to="/register"
                          style={{ textDecoration: "none" }}
                          className="fw_600 primary hover_a"
                        >
                          {" "}
                          Register
                        </Link>
                      </p>
                    </div>
                    <Link
                      to="/everify"
                      style={{ textDecoration: "none" }}
                      className="fw_600 secondary hover_a_s fs_14 mt-3 ms-2"
                    >
                      Forgot Password ?
                    </Link>
                  </div>
                  <div className="mb-3 d-flex align-items-center">
                    <Checkbox
                      checked={remember === true}
                      onChange={RememberMe}
                      size="small"
                      sx={{
                        color: `var(--primary)`,
                        "&.Mui-checked": {
                          color: `var(--primary)`,
                        },
                      }}
                    />
                    <p className="fs_14">Remember me</p>
                  </div>
                  {loading ? (
                    <LoaderButton classNames="login_btns" title="Sign In" />
                  ) : (
                    <SecondaryButton
                      // onClick={handleSubmit}
                      classNames="login_btns"
                      title="Sign In"
                    />
                  )}
                </form>
              </TabPanel>
              <TabPanel>
                {show && show ? (
                  <form style={{ width: "100%" }} onSubmit={handleOTPVerify}>
                    <input
                      name="phone"
                      value={number}
                      onChange={(e) => setNumber(e.target.value)}
                      type="text"
                      placeholder="Enter Phone Number *"
                      className="login_field "
                    />
                    <OtpInput
                      inputStyle="inputStyle"
                      value={otp}
                      onChange={setOtp}
                      numInputs={4}
                      // renderSeparator={<span>-</span>}
                      renderInput={(props) => <input {...props} />}
                    />

                    {loading ? (
                      <LoaderButton classNames="login_btns" title="Sign In" />
                    ) : (
                      <SecondaryButton
                        // onClick={handleSubmit}
                        classNames="login_btns"
                        title="Sign In"
                      />
                    )}
                  </form>
                ) : (
                  <form style={{ width: "100%" }} onSubmit={handleOTPSubmit}>
                    <div className="relative">
                      <input
                        name="phone"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        type="text"
                        placeholder="Enter Phone Number *"
                        className="login_field "
                      />
                    </div>
                    <div className="relative mt-4 p-0">
                      <div className="d-flex flex-wrap justify-content-between align-items-center login_btns ">
                        <div>
                          <p className="fs_14 mt-3 ms-2">
                            Don't have an account?
                            <Link
                              to="/register"
                              style={{ textDecoration: "none" }}
                              className="fw_600 primary hover_a"
                            >
                              {" "}
                              Register
                            </Link>
                          </p>
                        </div>
                        <Link
                          to="/everify"
                          style={{ textDecoration: "none" }}
                          className="fw_600 secondary hover_a_s fs_14 mt-3 ms-2"
                        >
                          Forgot Password ?
                        </Link>
                      </div>
                      <div className="mb-3 d-flex align-items-center">
                        <Checkbox
                          checked={remember === true}
                          onChange={RememberMe}
                          size="small"
                          sx={{
                            color: `var(--primary)`,
                            "&.Mui-checked": {
                              color: `var(--primary)`,
                            },
                          }}
                        />
                        <p className="fs_14">Remember me</p>
                      </div>
                      {loading ? (
                        <LoaderButton
                          classNames="login_btns"
                          title="Send OTP"
                        />
                      ) : (
                        <SecondaryButton
                          // onClick={handleSubmit}
                          classNames="login_btns"
                          title="Send OTP"
                        />
                      )}
                    </div>
                  </form>
                )}
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
