import React, { memo } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Grid } from "@mui/material";
// import { dynamicImg } from "../../data/Data";
import { useEffect } from "react";
import { GET } from "../../constant/RequestAuthService";
import { Img_url } from "../../constant";
import { useDispatch, useSelector } from "react-redux";
import { currentDate } from "./Home";
import { SliderLoader } from "../../components/skeletons/Loader";

const Banner = ({ detailpage }) => {
  const savedSlider = useSelector((state) => state.apiReducer);

  const dispatch = useDispatch();

  const handleSlider = async () => {
    const response1 = await GET("sliders");
    const ban = [...response1.data];

    const arr2 = [];

    let ind = 0,
      max = ban[0],
      min = ban[0];

    for (let j = 0; j < ban.length; j++) {
      if (max.is_order < ban[j].is_order) {
        max = ban[j];
      }
    }

    for (let i = 0; i < ban.length; i++) {
      for (let k = 0; k < ban.length; k++) {
        if (ban[k] !== null) {
          if (min.is_order > ban[k].is_order) {
            min = ban[k];
            ind = k;
          }
        }
      }
      arr2[i] = min;

      ban[ind] = null;
      min = max;
    }
    dispatch({ type: "sliderAd", payload: arr2 });
  };

  useEffect(() => {
    if (
      (savedSlider && savedSlider.slider.length === 0) ||
      currentDate !== savedSlider.date
    ) {
      handleSlider();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(savedSlider.slider);

  return (
    <>
      <div className="container home-big-slider mt-3">
        <div className="row ">
          <div className="col-sm-12">
            {savedSlider &&
            savedSlider.slider &&
            savedSlider.slider.length > 0 ? (
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlay
                autoPlaySpeed={4000}
                centerMode={false}
                className=""
                containerclassName="container-with-dots"
                dotListclassName=""
                draggable
                focusOnSelect={false}
                infinite
                itemclassName=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024,
                    },
                    items: 1,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                // showDots
                sliderclassName=""
                swipeable
              >
                {savedSlider.slider.map((data, ind) => (
                  <div key={ind}>
                    <a href="#/subCategories/1/1">
                      <img
                        style={{
                          height: !detailpage ? "320px" : "auto",
                        }}
                        src={`${Img_url}${data.image}`}
                        alt="img"
                        className="card_img"
                      />
                    </a>
                  </div>
                ))}
              </Carousel>
            ) : (
              <SliderLoader />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Banner);
