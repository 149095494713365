import { Badge, IconButton, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { styled } from "@mui/material/styles";
import OutsideClickHandler from "react-outside-click-handler";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";
import { GET } from "../../constant/RequestAuthService";
import { useDispatch, useSelector } from "react-redux";
import { Heading2 } from "../../components/heading/Heading";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { SecondaryButton } from "../buttons/Button";
import { SetSession } from "../../constant/Session";
// import { Img_url } from "../../constant";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    padding: "0 4px",
  },
}));

const Notification = () => {
  const notificationCount = useSelector(
    (state) => state.notificationReducer.count
  );
  const notificationMsg = useSelector((state) => state.notificationReducer.msg);
  const dispatch = useDispatch();
  const [drop, setDrop] = useState(false);

  useEffect(() => {
    typeof notificationCount === "object" && getNotificationCount();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getNotificationCount = async () => {
    const response = await GET("notify/count");
    if (response.status === 200) {
      dispatch({ type: "notifCount", payload: response.data.toString() });
    }
  };

  useEffect(() => {
    Array.isArray(notificationMsg) === false && getNotificationMessage();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(Array.isArray(notificationMsg));
  const getNotificationMessage = async () => {
    const response = await GET("notify/get");
    if (response.status === 200) {
      // console.log(response.data);
      dispatch({ type: "notifmssg", payload: response.data });
    }
  };
  const navigate = useNavigate();
  // console.log(notificationMsg);

  const navigateActive = async () => {
    navigate("/loginDashboard");
    await SetSession("activetabsMenu", 1);
  };
  return (
    <>
      <div className="relative css-tooltip">
        <IconButton
          onClick={() => setDrop(!drop)}
          aria-label="show 17 new notifications"
        >
          <StyledBadge badgeContent={notificationCount} color="secondary">
            <NotificationsIcon className="hover_icon" />
          </StyledBadge>
        </IconButton>
        <OutsideClickHandler onOutsideClick={() => setDrop(false)}>
          <AnimatePresence>
            {drop && (
              <motion.div
                initial={{ scale: 0, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0, opacity: 0 }}
                className="notification_acive"
              >
                <CloseIcon
                  sx={{ fontSize: "1.3rem" }}
                  className="ms-auto d-block pointer secondary"
                  onClick={() => setDrop(false)}
                />
                {notificationMsg && typeof notificationMsg === "object" ? (
                  <>
                    {notificationMsg.length === 0 ? (
                      <>
                        {" "}
                        <Heading2 title="No Notification at the moment" />
                      </>
                    ) : (
                      <>
                        <ul className={" list-unstyled "}>
                          {notificationMsg.slice(0, 3).map((data, i) => (
                            <li key={i} className="my-2 ">
                              <div
                                className={` ${
                                  data.is_read === 0 && "isRead"
                                }  dropdown-item fw_700 d-flex align-items-center justify-content-between my_list_not br_8 `}
                              >
                                <span
                                  onClick={() =>
                                    navigate(
                                      `/productDetails/${data.product_id}`
                                    )
                                  }
                                  className="pointer fs_12"
                                >
                                  {data.product.name}
                                </span>{" "}
                                <span
                                  className={
                                    data.status_id === 1
                                      ? "primary"
                                      : "secondary"
                                  }
                                >
                                  {data.status_id === 1 ? "Active" : "Pending"}
                                </span>
                              </div>
                            </li>
                          ))}{" "}
                          <li className="mt-3 ">
                            <SecondaryButton
                              onClick={
                                navigateActive

                                // navigate("/notifications")
                              }
                              title="Show More"
                              classNames="w-100"
                            />
                          </li>
                        </ul>
                      </>
                    )}
                  </>
                ) : (
                  <div className="d-flex align-items-center justify-content-center">
                    <Skeleton variant="rectangular" width={210} height={60} />
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </OutsideClickHandler>
      </div>
    </>
  );
};

export default Notification;
