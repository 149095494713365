import { CircularProgress, ClickAwayListener, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { POST } from "../../constant/RequestAuthService";
import { GetSession, SetSession } from "../../constant/Session";
import { Json, Stringify } from "../../constant/Util";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Img_url } from "../../constant";
import ProductSeachDropDown from "./ProductSeachDropDown";

const SearchBar = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState(false);
  const [click, setClick] = useState(false);
  const [showCatDrop, setShowCatDrop] = useState(false);
  const [search, setSearch] = useState("");
  const [sendData, setSendData] = useState();
  const { pathname } = useLocation();
  const [name, setName] = useState(" All Categories");
  const [category_id, setCategory_id] = useState(null);
  const [sub_category_id, setSub_category_id] = useState(null);
  const [isData, setIsData] = useState(false);
  const [getData, setGetData] = useState(false);
  const categoryList = useSelector((state) => state.apiReducer);
  const [searchResults, setSearchResults] = useState([]);
  const searchResultRef = useRef(null);

  const prevSearch = Json(GetSession("sendData"));
  // console.log(prevSearch);
  useEffect(() => {
    if (prevSearch !== null && pathname === "/product") {
      if (
        prevSearch.search !== "" ||
        prevSearch.category_id !== "" ||
        prevSearch.sub_category_id !== ""
      ) {
        setSendData(prevSearch);
        setSearch(prevSearch.search);
        setCategory_id(prevSearch.category_id);
        setName(prevSearch.name);
        setSub_category_id(prevSearch.sub_category_id);
        setIsData(false);
        setTimeout(() => {
          setIsData(true);
        }, 300);
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const display = (data) => {
    setName(data.name);
    setCategory_id(data.id);
  };

  const displayall = () => {
    setName("All Categories");
    setCategory_id(null);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (search === "") {
      return;
    } else {
      if (pathname === "/product") {
        setStatus(false);
      } else {
        setStatus(true);
      }
      dispatch({ type: "loadingtrue" });
      const response = await POST("products", sendData);
      if (response.status === 200) {
        dispatch({ type: "loadingfalse" });
        dispatch({
          type: "getSearchProduct",
          payload: response.data,
        });
        setGetData(!getData);
        setShowCatDrop(false);
      }
      dispatch({ type: "loadingfalse" });
      // console.log(category_id, input);
    }
  };
  const handleSearchi = async () => {
    if (search === "") {
      return;
    } else {
      if (pathname === "/product") {
        setStatus(false);
      } else {
        setStatus(true);
      }
      dispatch({ type: "loadingtrue" });
      const response = await POST("products", sendData);
      if (response.status === 200) {
        // console.log(response.data);
        dispatch({ type: "loadingfalse" });
        dispatch({
          type: "getSearchProduct",
          payload: response.data,
        });
        setGetData(!getData);
        setShowCatDrop(false);
      }
      dispatch({ type: "loadingfalse" });
      // console.log(category_id, input);
    }
  };

  useEffect(() => {
    SetSession("sendData", Stringify({ ...sendData, name }));
  }, [getData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getData = setTimeout(() => {
      if (search?.length > 2) {
        handleSearchi();
      }
      if (search?.length === 0) {
        setStatus(false);
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [search, sendData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSendData({ category_id, search, sub_category_id });
  }, [category_id, search, sub_category_id]);

  useEffect(() => {
    if (isData === true) {
      if (prevSearch !== "undefined") {
        const handleReload = async () => {
          // console.log(true);
          dispatch({ type: "ReloadLoadingStart" });
          const response = await POST("products", sendData);
          if (response.status === 200) {
            dispatch({
              type: "getSearchProduct",
              payload: response.data,
            });
            dispatch({ type: "ReloadLoadingStop" });
          }
        };
        handleReload();
      }
      dispatch({ type: "LoadingStopProduct" });
    }
  }, [isData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    category_id !== null && setShowCatDrop(false);
  }, [category_id]);

  // const highlightWords = (text, keyword) => {
  //   const regex = new RegExp(`(${keyword})`, "gi");
  //   const parts = text.split(regex);

  //   return parts.map((part, index) => {
  //     if (index % 2 === 1) {
  //       return (
  //         <span key={index} style={{ backgroundColor: "yellow" }}>
  //           {part}
  //         </span>
  //       );
  //     } else {
  //       return <span key={index}>{part}</span>;
  //     }
  //   });
  // };
  // console.log(highlightWords);

  // useEffect(() => {
  //   const highlightedResults = searchResults.map((result) => ({
  //     ...result,
  //     text: highlightWords(result.text, search),
  //   }));
  //   setSearchResults(highlightedResults);
  // }, [search, searchResults]);
  // // console.log(highlightWords);

  useEffect(() => {
    if (searchResultRef.current) {
      searchResults.forEach((result) => {
        const regex = new RegExp(`(${search})`, "gi");
        const highlightedText = result.text.replace(
          regex,
          '<span style="background-color: yellow">$1</span>'
        );
        searchResultRef.current.innerHTML += `<div>${highlightedText}</div>`;
        // console.log(highlightedText);y
      });
    }
  }, [search, searchResults]);
  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setStatus(false)}>
        <ClickAwayListener onClickAway={() => setShowCatDrop(false)}>
          <form onSubmit={(e) => handleSearch(e)}>
            <div className="relative">
              <input
                placeholder="Search A Product"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="search_input_field2"
              />
              <div>
                <IconButton
                  // onClick={() => navigate("/products")}
                  type="submit"
                  className="icon seacrh_icon_input_inside"
                  sx={{ padding: "5px 10px 5px 10px" }}
                >
                  <SearchIcon
                    sx={{
                      color: "#000",
                    }}
                  />
                </IconButton>
              </div>
              <div
                // style={{ border: "2px solid red" }}
                className=" category_input"
              >
                <div className="dropdown_menu d-flex align-items-center">
                  <div>
                    <div
                      onClick={() => setShowCatDrop(!showCatDrop)}
                      className="d-flex reg_sm_field_input align-items-center"
                    >
                      <div className="text_over_ellipsis fs_13 fw_500">
                        {name}
                      </div>

                      <ExpandMoreIcon
                        sx={{ color: "#000" }}
                        className={`${
                          !showCatDrop
                            ? "icon_hover_expand"
                            : "icon_hover_expand_down"
                        }`}
                      />
                    </div>
                  </div>

                  <ul
                    style={{ right: "-80%" }}
                    className={`${
                      showCatDrop && "show_login_reg_dropdown_cat"
                    } list-unstyled login_reg_dropdown br_0 p-0`}
                  >
                    <li onClick={displayall}>
                      <div className="dropdown-item pointer fs_13">
                        All Categories
                      </div>
                    </li>
                    {categoryList && categoryList.categoryList.length > 0 ? (
                      categoryList.categoryList.map((data, ind) => (
                        <li key={ind}>
                          <div
                            className="dropdown-item pointer fs_13"
                            onClick={() => display(data)}
                          >
                            <span className="mr-1">
                              <img
                                src={`${Img_url}${data.picture}`}
                                alt="img"
                                // className="card_img"
                                className="img-fluid me-2"
                                width="20px"
                              />
                            </span>
                            {data.name}
                          </div>
                        </li>
                      ))
                    ) : (
                      <CircularProgress color="secondary" />
                    )}
                  </ul>
                </div>
              </div>
              <div
                className={` ${
                  status === true ? "product_search_box" : "d-none"
                }`}
              >
                <ProductSeachDropDown
                  searchResults={searchResults}
                  search={search}
                  setClick={setClick}
                  setStatus={setStatus}
                />
                <div ref={searchResultRef}></div>
              </div>
            </div>
          </form>
        </ClickAwayListener>
      </OutsideClickHandler>
    </>
  );
};

export default SearchBar;
